import { Button, ButtonGroup, Dropdown } from "react-bootstrap";

import Sidebar from "../components/Sidebar";

const Home = () => {
    return(
        <div>
            <p>Home Page!</p>
        </div>
    )
}

export default Home;