import React from "react";
import {Nav} from "react-bootstrap";
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
  } from 'cdbreact';
  import { NavLink, Link } from 'react-router-dom';

import SidebarMenu from 'react-bootstrap-sidebar-menu';

const SideBar = () => {
    return (
        <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
          <CDBSidebar textColor="#fff" backgroundColor="#333">
            <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
              {/* <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
                Itineri
              </a> */}
              {/* <Link to="/itineri" className="nav-link">Itineri</Link> */}
            </CDBSidebarHeader>
    
            <CDBSidebarContent className="sidebar-content">
              <CDBSidebarMenu>
                <NavLink exact to="/" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="columns">Today</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/tables" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="table">Tables</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/profile" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="user">Profile page</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/analytics" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="chart-line">Analytics</CDBSidebarMenuItem>
                </NavLink>
    
                <NavLink exact to="/hero404" target="_blank" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="exclamation-circle">404 page</CDBSidebarMenuItem>
                </NavLink>
              </CDBSidebarMenu>
            </CDBSidebarContent>

            <CDBSidebarHeader prefix={<i className="fa fa-bars fa-small"></i>}>
              <Link to="/itineri" className="nav-link">Itineri</Link>
            </CDBSidebarHeader>
    
            <CDBSidebarFooter style={{ textAlign: 'center' }}>
              <div
                style={{
                  padding: '20px 5px',
                }}
              >
                <NavLink exact to="/add" activeClassName="activeClicked">
                    <CDBSidebarMenuItem icon="plus">New Itinerary</CDBSidebarMenuItem>
                </NavLink>
              </div>
            </CDBSidebarFooter>
          </CDBSidebar>
        </div>
      );
};

export default SideBar;

{/* const Sidebar = withRouter(Side); */}

    
            {/* <Nav className="col-md-12 d-none d-md-block bg-light sidebar"
            activeKey="/home"
            onSelect={selectedKey => alert(`selected ${selectedKey}`)}
            >
                <div className="sidebar-sticky"></div>
            <Nav.Item>
                <Nav.Link href="/home">Active</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="link-1">Link</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="link-2">Link</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="disabled" disabled>
                Disabled
                </Nav.Link>
            </Nav.Item>
            </Nav> */}
            {/* import SidebarMenu from 'react-bootstrap-sidebar-menu'; */}