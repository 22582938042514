import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';

const NavBar = () => {
    return(
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
                <Link to="/" className="navbar-brand">Itineri</Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Link to="/itineri" className="nav-link">Itineri</Link>
                        <Link to="/metrics" className="nav-link">Metrics</Link>
                        <Link to="/dashboard" className="nav-link">Dashboard</Link>
                    </Nav>
                    <Nav>    
                        <Link to="/itineri" className="nav-link">Login</Link>
                        <Link to="/itineri" className="nav-link">Logout</Link>
                    </Nav>

                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar;