import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { Route, Switch, Redirect, useHistory } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Itineri from "./pages/Itineri";
import ScoreFactory from "./pages/ScoreFactory";
import Layout from './components/Layout';

import NotFound from "./components/NotFound"

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="metrics" element={<ScoreFactory />} />
          <Route path="itineri" element={<Itineri />} />
          <Route path="not-found" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}



export default App;
