import { Outlet, Link } from "react-router-dom";
import NavBar from "./NavBar";
import { Container, Col, Row } from "react-bootstrap";

import SideBar from "./Sidebar";

const Layout = () => {
  return (
    <>
      <NavBar />
      <Row>
        <Col md="auto">
          <SideBar />
        </Col>
        <Col>
          <Outlet />
        </Col>
      </Row>
    </>
  )
};

export default Layout;